const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.wecomplai.forwoodsafety.com',
    APP_CLIENT_ID: '',
    APIGATEWAY: 'https://breglcjba1.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://gqt3jundjc.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'staging',
    VERSION: 'chore/RTRM-4174-add-staging-identifier',
    HOSTNAME: 'https://incidents.staging.wecomplai.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.staging.wecomplai.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.staging.wecomplai.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.staging.wecomplai.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

